import type { ComponentType } from "react"
import { addPropertyControls, ControlType } from "framer"
import { Data } from "./Data.tsx"

// Componente para atualizar a variável
export function UpdateTextBanner(Component): ComponentType {
    return (props) => {
        const defaultValues = Data[0].texts
        const textForUrl = Data.find(
            (item) => item.url === window.location.pathname
        )
        if (textForUrl) {
            props = { ...textForUrl.texts }
        } else {
            props = { ...defaultValues }
        }
        return <Component {...props} />
    }
}

addPropertyControls(UpdateTextBanner, {
    setTextBanner: {
        type: ControlType.String,
        title: "Set TextBanner",
    },
})
